import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { languages } from "../constants/languages";

import Section from "../components/Section/Section";
import homeImage from "/imgs/Land1.webp";
import homeImage2 from "/imgs/Land2.webp";
import servicesImage from "/imgs/Services.webp";
import services2Image from "/imgs/Services2.webp";
import services3Image from "/imgs/Services3.webp";
import services4Image from "/imgs/Services4.webp";
import services5Image from "/imgs/Services5.webp";
import services6Image from "/imgs/Services6.webp";
import weldingIcon from "/imgs/aey.webp";
import engineeringIcon from "/imgs/bee.webp";
import servIcon from "/imgs/cee.webp";
import productIcon from "/imgs/dee.webp";
import { FaInstagram, FaLinkedinIn, FaFacebook } from "react-icons/fa";

import lincolnLogo from "/imgs/logos/lincoln-electric-logo.webp";
import hyperthermLogo from "/imgs/logos/hypertherm-logo.webp";
import bugoLogo from "/imgs/logos/bugo-systems-logo.webp";
import sfeLogo from "/imgs/logos/sfe-logo.webp";
import sawyerLogo from "/imgs/logos/sawyer-logo.webp";
import promotechLogo from "/imgs/logos/promotech-logo.webp";
import harrisLogo from "/imgs/logos/harris-logo.webp";
import deltaHeatLogo from "/imgs/logos/delta-heat-logo.webp";
import dmiLogo from "/imgs/logos/dmi-logo.webp";
import trafimetLogo from "/imgs/logos/trafimet-logo.webp";
import koikeLogo from "/imgs/logos/koike-logo.webp";

const Home = () => {
  const { t, i18n } = useTranslation();
  const [currentHomeImageIndex, setCurrentHomeImageIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);
  const homeImages = [homeImage, homeImage2];
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const iframeRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFading(true);
      setTimeout(() => {
        setCurrentHomeImageIndex((prevIndex) => (prevIndex === 0 ? 1 : 0));
        setIsFading(false);
      }, 5000);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsIframeLoaded(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 }
    );

    const currentIframeRef = iframeRef.current;
    if (currentIframeRef) {
      observer.observe(currentIframeRef);
    }

    return () => {
      if (currentIframeRef) {
        observer.unobserve(currentIframeRef);
      }
    };
  }, []);

  return (
    <>
      <html lang={i18n.language} />
      {languages.map((lang) => (
        <link
          key={lang.code}
          rel="alternate"
          hrefLang={lang.code}
          href={`https://aws-iraq.com/${lang.code}${window.location.pathname}`}
        />
      ))}
      <link
        rel="alternate"
        hrefLang="x-default"
        href={`https://aws-iraq.com${window.location.pathname}`}
      />
      <meta property="og:locale" content={i18n.language} />
      <title>{t("meta.home.title", "Home - AWS Co.")}</title>
      <meta
        name="description"
        content={t(
          "meta.home.description",
          "AWS Co., is an Iraqi company with headquarter based in Baghdad and offices all over Iraqi territory, offering a wide range of services and engineering solutions for Oil & Gas, steel structure work shop, Power sector and other major industries in the field."
        )}
      />

      <div className="landing">
        <Section
          id="home"
          title={t("1", "Home")}
          backgroundImage={homeImages[currentHomeImageIndex]}
          isFading={isFading}
          hasOverlay={true}
          isLazy={false}
        >
          <div className="square" dir={i18n.language === "ar" ? "rtl" : "ltr"}>
            <h1
              className="home-h1"
              style={{
                textAlign: i18n.language === "ar" ? "right" : "left",
                left: i18n.language === "ar" ? "auto" : "1%",
                right: i18n.language === "ar" ? "1%" : "auto",
              }}
            >
              <span className="outline-text1">{t("2", "Shaping")}</span>
              <span>{t("3", "Tomorrow's")}</span>
              <span>{t("4", "Infrastructure")}</span>
              <span>{t("5", "with Today's")}</span>
              <span className="outline-text1">{t("6", "Expertise")}</span>
            </h1>

            <div className="cta-section">
              <p className="cta-text">{t("7", "Get in touch with us. Check out the details of our offices.")}</p>
              <Link to="/contact" className="cta-button">
                {t("8", "Contact Us")}
              </Link>
            </div>
          </div>
        </Section>
      </div>

      <Section
        id="servicesagencies"
        title="Agencies"
        backgroundImage={servicesImage}
      >
        <div className="square" dir={i18n.language === "ar" ? "rtl" : "ltr"}>
          <h1>{t("10", "Empowering Industries with Precision, Innovation, and Reliable Welding Solutions.")}</h1>
          <div className="video-container" ref={iframeRef}>
            {isIframeLoaded && (
              <iframe
                width="1280"
                height="720"
                src="https://www.youtube.com/embed/P_jUamZuUT0?si=24XP1BxpzA4ARNgN"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            )}
          </div>
          <h1>{t("11", "Advance Welding Solution (AWS) is a premier exclusive distributor for leading international companies specializing in welding and comprehensive welding solutions. We proudly represent a range of globally recognized brands, providing cutting-edge products and services to meet the diverse needs of our clients in the welding industry.")}</h1>
        </div>
      </Section>
      <section className="partners-section">
        <div className="partner-card">
          <img
            src={lincolnLogo}
            alt="Lincoln Electric"
            className="partner-logo"
          />
          <p className="partner-description">
            Specialized: Welding Machines and Consumable
            <br />
            Origin: USA & Europe
          </p>
        </div>
        <div className="partner-card">
          <img src={hyperthermLogo} alt="Hypertherm" className="partner-logo" />
          <p className="partner-description">
            Specialized: Cutting Solutions, Plasma Laser
            <br />
            Origin: USA
          </p>
        </div>
        <div className="partner-card">
          <img src={bugoLogo} alt="Bug-O Systems" className="partner-logo" />
          <p className="partner-description">
            Cutting and Welding Mechanization System
            <br />
            Origin: USA
          </p>
        </div>
        <div className="partner-card">
          <img src={sfeLogo} alt="SFE Group" className="partner-logo" />
          <p className="partner-description">
            Cold Cutting with Specialized Fabrication Equipment Rentals
            <br />
            Origin: USA & Europe
          </p>
        </div>
        <div className="partner-card">
          <img
            src={sawyerLogo}
            alt="Sawyer MFG Company"
            className="partner-logo"
          />
          <p className="partner-description">
            Pipeline & Welding Equipment
            <br />
            Origin: USA & Europe
          </p>
        </div>
        <div className="partner-card">
          <img src={promotechLogo} alt="Promotech" className="partner-logo" />
          <p className="partner-description">
            Drilling, Punching, Beveling, and Welding Automation Equipment
            <br />
            Origin: Europe, Poland
          </p>
        </div>
        <div className="partner-card">
          <img
            src={harrisLogo}
            alt="Harris - A Lincoln Electric Company"
            className="partner-logo"
          />
          <p className="partner-description">
            Development and manufacture of arc welding products, plasma and
            oxy-fuel cutting equipment and accessories
            <br />
            Origin: USA
          </p>
        </div>

        <div className="partner-card">
          <img
            src={deltaHeatLogo}
            alt="Delta Heat Services"
            className="partner-logo"
          />
          <p className="partner-description">
            Specialist in Heat Treatment
            <br />
            Origin: Europe
          </p>
        </div>

        <div className="partner-card">
          <img src={dmiLogo} alt="DMI International" className="partner-logo" />
          <p className="partner-description">
            Heavy Equipment
            <br />
            Origin: USA
          </p>
        </div>

        <div className="partner-card">
          <img src={trafimetLogo} alt="Trafimet" className="partner-logo" />
          <p className="partner-description">
            Trafimet professional welding accessories
            <br />
            Origin: Italy, Europe
          </p>
        </div>

        <div className="partner-card">
          <img
            src={koikeLogo}
            alt="Koike Aronson Ransome"
            className="partner-logo"
          />
          <p className="partner-description">
            Manufacturer of cutting, welding, & positioning equipment
            <br />
            Origin: Japan
          </p>
        </div>
      </section>
      <Section id="services1" title={t("12", "Our Services")}>
        <div className="square2" dir={i18n.language === "ar" ? "rtl" : "ltr"}>
          <h1>{t("12", "Our Services")}</h1>
          <p>{t("13", "Advance Welding Solutions Company Ltd. (AWS Co.) offers comprehensive services designed to meet the diverse needs of the Oil & Gas, Power, and other major industries in Iraq. Our expertise and commitment to quality ensure that we deliver reliable and efficient solutions across all our service offerings.")}</p>
        </div>
      </Section>
      <Section
        id="services2"
        title={t("14", "Welding Solutions")}
        backgroundImage={services2Image}
      >
        <div className="services2">
          <div className="square2" dir={i18n.language === "ar" ? "rtl" : "ltr"}>
            <h1>
              <img src={weldingIcon} alt="A Icon" />
              {t("14", "Welding Solutions")}
            </h1>
            <p className="services2-p">{t("15", "Supply of Lincoln Electric welding equipment and consumables, commissioning and repair services for all Lincoln Electric products, technical support, and consultation to ensure the optimal selection and application of welding solutions.")}</p>
          </div>
        </div>
      </Section>
      <Section
        id="services3"
        title={t("16", "Engineering Solutions")}
        backgroundImage={services3Image}
      >
        <div className="square2" dir={i18n.language === "ar" ? "rtl" : "ltr"}>
          <h1>
            {t("16", "Engineering Solutions")}
            <img src={engineeringIcon} alt="B Icon" />
          </h1>
          <p>{t("17", "Customized engineering solutions for Oil & Gas, Power, and industrial sectors, design and development of specialized welding systems, support for project planning and execution to meet industry standards.")}</p>
        </div>
      </Section>
      <Section
        id="services4"
        title={t("18", "Service & Maintenance")}
        backgroundImage={services4Image}
      >
        <div className="square2" dir={i18n.language === "ar" ? "rtl" : "ltr"}>
          <h1>
            <img src={servIcon} alt="C Icon" />
            {t("18", "Service & Maintenance")}
          </h1>
          <p>{t("19", "Full-fledged service center for the repair and maintenance of welding equipment, preventive maintenance programs to extend equipment life and ensure peak performance, on-site support and troubleshooting for critical operations.")}</p>
        </div>
      </Section>
      <Section
        id="services5"
        title={t("20", "Product Demonstrations & Training")}
        backgroundImage={services5Image}
      >
        <div className="services5">
          <div className="square2" dir={i18n.language === "ar" ? "rtl" : "ltr"}>
            <h1>
              {t("20", "Product Demonstrations & Training")}
              <img src={productIcon} alt="A Icon" />
            </h1>
            <p className="services2-p">{t("21", "Demonstrations of new and existing welding products to ensure proper usage and application, training programs for client teams to enhance skills and operational efficiency. Our team of qualified and experienced engineers is dedicated to providing top-notch services, ensuring that our clients receive the most effective and innovative solutions tailored to their specific needs.")}</p>
          </div>
        </div>
      </Section>
      <Section id="services6" title={t("22", "Services6")}>
        <div className="services6" dir={i18n.language === "ar" ? "rtl" : "ltr"}>
          <p>{t("23", "Our team of qualified and experienced engineers is dedicated to providing top-notch services, ensuring that our clients receive the most effective and innovative solutions tailored to their specific needs.")}</p>
        </div>
      </Section>

      <Section id="end" title={t("90", "Follow Us")} backgroundImage={services6Image}>
        <div dir={i18n.language === "ar" ? "rtl" : "ltr"}>
          <h1 className="end-h1">{t("91", "Follow Us")}</h1>
          <p className="end-p">{t("92", "Stay updated with our latest news, projects, and innovations by following us on social media.")}</p>
          <div className="social-media-links">
            <a
              href="https://www.instagram.com/aws_iraq.lincoln.distributor"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <FaInstagram size={60} />
            </a>

            <a
              href="https://www.linkedin.com/in/aws-iraq-lincoln-distributor-8b17a7293"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
            >
              <FaLinkedinIn size={60} />
            </a>
            <a
              href="https://www.facebook.com/lincoln.aws"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="facebook"
            >
              <FaFacebook size={60} />
            </a>
          </div>
        </div>
      </Section>
    </>
  );
};

export default Home;
